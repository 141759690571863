@import "src/styles/breakpoints";

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    .caption {
        margin-top: -1rem;
        font-size: clamp(1em, 1.5vw, 1.5em);
    }

    :global {
        .title {
            margin-top: -1rem;
        }
    }


    @include screensize('small') {
        padding: 2rem;

        .caption {
            margin-top: -1rem;
        }
    }

    @include screensize('medium') {
        .caption {
            margin-top: -2rem;
        }
    }
}