$breakpoints: (
    'xsmall': 481px,
    'small': 769px,
    'medium': 1024px,
    'large': 1280px,
    'xlarge': 1440px,
    'xxlarge': 1920px) !default;

@mixin screensize($breakpoint) {

    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}