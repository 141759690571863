.icons {
    // Font minimum, preferred and maximum value
    font-size: clamp(var(--min), var(--val), var(--max));
}

.icons {
    --min: 1rem;
    --val: 1vw;
    --max: 2rem;

    display: flex;
    align-items: center;
    cursor: pointer;
}