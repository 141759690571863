@import 'colors';

@font-face {
  font-family: 'ClickerScript';
  src: url('/fonts/ClickerScript/ClickerScript-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url('/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsLight';
  src: url('/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsExtraLight';
  src: url('/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

h1,
h2,
p,
a,
button,
span,
.title {
  // Font minimum, preferred and maximum value
  font-size: clamp(var(--min), var(--val), var(--max));
}

// Font size variables
.title {
  --min: 3rem;
  --val: 6vw;
  --max: 5rem;
}

h1 {
  --min: 3rem;
  --val: 5vw;
  --max: 4rem;
}

h2 {
  --min: 1.75rem;
  --val: 5vw;
  --max: 2.25rem;
}

h3 {
  --min: 1.5rem;
  --val: 3vw;
  --max: 2rem;
}

p,
a,
button {
  --min: 1rem;
  --val: 1.25vw;
  --max: 1.75rem;
}

span {
  --min: 0.85rem;
  --val: 1.25vw;
  --max: 1.5rem;
}

h1 {
  font-family: 'PoppinsSemiBold', serif;
  color: $primary;
}

h2,
h3 {
  font-family: 'PoppinsMedium', serif;
  color: $secondary;
}

h3 {
  color: $primary;
}

p {
  font-family: 'Poppins', serif;
  color: $primary;
  text-align: justify;
  line-height: 2rem;
}

span {
  font-family: 'PoppinsExtraLight', serif;
  color: $primary;
}

a {
  font-family: 'PoppinsLight', serif;
  color: $secondary;
}

a:hover {
  font-style: italic;
  text-decoration: underline;
}

button {
  font-family: 'PoppinsSemiBold', serif;
  text-transform: capitalize;
  color: $secondary;
}

.title {
  font-family: 'ClickerScript';
  padding: 0 1rem;
  font-weight: normal;
}
