@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.about {
  display: flex;
  flex: 1;
  align-self: flex-start;

  @include screensize('small') {
    position: relative;
    flex-wrap: wrap;
  }

  .inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include screensize('small') {
      justify-content: flex-start;
    }
  }

  .avatar {
    border-radius: 50%;
    width: 60%;
    max-width: 300px;
    z-index: 2;
    margin-bottom: -30%;
  }

  .info {
    width: 100%;
    background-color: $highlight;
  }

  .text {
    padding: 2rem;
    text-align: justify;
    margin-top: 30%;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    word-break: break-all;
  }
}
