@import "src/styles/colors";
@import "src/styles/breakpoints";

.container {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    box-shadow: 0px 4px 4px $disabled;
    padding: 1rem;
    position: sticky;
    top: 0;
    z-index: 3;

    @include screensize('small') {
        padding: 0;
        flex-direction: column;
        justify-content: center;
        box-shadow: none;
    }
}

.icon {
    background-color: $white;

    .menu {
        --min: 2rem;
        --val: 4vw;
        --max: 3rem;
    }

    @include screensize('small') {
        display: none;
    }
}