@import "src/styles/colors";
@import "src/styles/breakpoints";

.menu {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-x: hidden;
    width: 0;
    background-color: $white;
    box-shadow: 0px 4px 4px 0px $disabled;
    display: flex;
    transition: 0.5s;

    @include screensize('small') {
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
        box-shadow: none;
        position: relative;
        height: unset;
        overflow-x: unset;
        width: 100%;
        justify-content: center;
    }
}

.expanded {
    width: 75vw;
    z-index: 3;
}

.unexpanded {
    width: 0;
}

.inner {
    display: flex;
    flex-direction: column;

    @include screensize('small') {
        width: 70%;
        flex-direction: row;
    }
}

.button {
    cursor: pointer;
    background-color: $white;
    padding: 1.5rem;
    width: 100%;
    text-align: left;

    &:hover {
        span {
            font-style: italic;
            text-decoration: underline;
        }
    }

    span {
        --min: 1rem;
        --val: 1.25vw;
        --max: 1.5rem;
    }

    @include screensize('small') {
        padding: 0.5rem;
        text-align: center;
    }
}