@import "src/styles/colors";
@import "src/styles/breakpoints";

.content {
    display: flex;
    margin-top: 3rem;
    flex: 1;

    @include screensize('small') {
        margin-bottom: 2rem;
        justify-content: center;
    }
}

.container {
    width: 100%;

    @include screensize('small') {
        width: 90%;
    }

    @include screensize('large') {
        width: 80%;
    }
}