.iconLink {
    .link {
        text-align: center;
        padding: 1rem;
    }

    .icon {
        // Font minimum, preferred and maximum value
        font-size: clamp(1rem, 1.5vw, 2rem);
        margin-right: 0.5rem;
    }
}