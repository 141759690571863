@import "src/styles/colors";
@import "src/styles/breakpoints";

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: $highlight;
    margin-top: auto;

    @include screensize('small') {
        flex-direction: row;

        .name {
            margin-left: 0.5rem;
        }
    }
}