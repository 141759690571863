@import "src/styles/breakpoints";
@import "src/styles/colors";

.home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 4rem;

    @include screensize('small') {
        flex-wrap: wrap;
        flex-direction: row;
        column-gap: 2rem;
    }
}

.main {
    display: flex;
    flex: 3;
    background-color: $background;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5rem;

    @include screensize('small') {
        flex-wrap: wrap;
        flex-direction: column;
        column-gap: 2rem;
    }
}