@import 'src/styles/colors';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 50%;
  justify-content: space-evenly;
}

.caption {
  color: $secondary;
}

.error {
  color: $muted;
  font-size: clamp(1rem, 3vw, 1.25rem);
}
