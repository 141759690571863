$white: #ffff;

$primary: #36413e;
$secondary: #5c5168;

$highlight: #ffd8be;
$background: #fff4ec;

$muted: #7f7f7f;
$disabled: #d9d9d9;

$error: #a33a3a;
